const leagues = [
    { 
        display: "🏈 NFL",
        value: "nfl",
        sportKey: "americanfootball_nfl",
        markets: {
            "Game Spread": {
                strategy: ["Get spreads", "Get stats", "Think step by step about how the matchup will play out based on offense <> opposing defense interaction", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Moneyline": {
                strategy: ["Get h2h odds and stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Over/Under Points": {
                strategy: ["Get totals odds and team stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Player Props": {
                strategy: ["Get the NFL totals odds", "Use the event id from the totals odds to get the prop odds", "Get player stats", "Compare the prop odds to the player's 5 game average and recommend props that are likely to hit"],
                premium_only: false
            },
            "🏆 SageWager Parlay": {
                strategy: ["Get the NFL defense vs position rankings from https://draftedge.com/nfl/nfl-defense-vs-pos/ and get the NFL schedule", "Identify teams and position groups with the most favorable matchups and share them with the user", "Use the event ids of those matchups to get the prop odds", "Get stats for those players and recommend picks based on the favorable matchups",],
                premium_only: true,
                placeholder: "Which group of games do you want to check for favorable matchups?"
            },
            "🏆 Love your Meteorologist Parlay": {
                strategy: ["Get the upcoming NFL schedule", "IF the team plays in an outdoor stadium, get the weather forecast by inserting the zip code and visiting this URL: https://forecast.weather.gov/zipcity.php?inputstring={5 digit zip code of home team stadium}", "Use the event ids of those matchups to get the prop odds", "Account for the weather and how it will affect the game script. Recommend picks based on the favorable weather scenarios",],
                premium_only: true,
                placeholder: "Which games do you think will be affected by weather?"
            },
            "🏆 SageWager Streak Seeker Parlay": {
                strategy: ["Get the NFL schedule", "Use the event ids to get prop odds", "Get player stats and identify players that are on a consistent streak for a particular stat each of their last 5 games", "Recommend a parlay of props with the prop line based on the theory that they will keep their consistent streak going and achieve the same for that stat in their next game",],
                premium_only: true,
                placeholder: "Pick a team or two to check for streaks"
            },
            "🏆 SageWager Follow the Sharps": {
                strategy: ["Get the NFL schedule", "Do research on betting trends for each game", "Share your findings with the user and recommend picks based on following the trends",],
                premium_only: true,
                placeholder: "Pick a game or two to check the sharp picks on"
            },
        },
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
            "Spread": {
                "display": "Spread",
                "value": "spreads"
            },
            "Total Points": {
                "display": "Total Points",
                "value": "totals"
            },
            "Alternate Spreads": {
                "display": "Alternate Spreads",
                "value": "alternate_spreads"
            },
            "Alternate Totals": {
                "display": "Alternate Totals",
                "value": "alternate_totals"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
            "Quarterly Results": {
                "display": "Quarterly Results",
                "value": "h2h_q1,h2h_q2,h2h_q3,h2h_q4,spreads_q1,spreads_q2,spreads_q3,spreads_q4,totals_q1,totals_q2,totals_q3,totals_q4"
            },
            "QB Props": {
                "display": "QB Props",
                "value": "player_pass_tds,player_pass_yds,player_pass_completions,player_pass_attempts,player_pass_interceptions,player_pass_longest_completion"
            },
            "Rushing Props": {
                "display": "Rushing Props",
                "value": "player_rush_yds,player_rush_attempts,player_rush_longest"
            },
            "Receiving Props": {
                "display": "Receiving Props",
                "value": "player_receptions,player_reception_yds,player_reception_longest"
            },
            "TD Scorer Props": {
                "display": "TD Scorer Props",
                "value": "player_anytime_td,player_1st_td"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
            "Vegas Over/Unders": {
                "display": "Vegas Win Totals",
                "prompt": "search web for the vegas full season win totals for the teams, and use that to understand projected performance",
                "premium_only": true
            },
            "Defensive Rankings": {
                "display": "Get defensive rankings",
                "prompt": "Use scrape_url to get the NFL defense vs position rankings from https://draftedge.com/nfl/nfl-defense-vs-pos/",
                "premium_only": true
            },
            "Fade the Public": {
                "display": "Fade the Public",
                "prompt": "use search web to understand which side of the bet is getting the majority of the money, and then fade that side",
                "premium_only": true
            },
            "Weather Forecast": {
                "display": "Get weather forecast",
                "prompt": "IF the home team plays in an outdoor stadium, get the weather forecast by inserting the zip code and scraping this URL: https://forecast.weather.gov/zipcity.php?inputstring={5 digit zip code of home team stadium}",
                "premium_only": true
            },
        }

    },
    {
        display: "🏈 NFL Preseason",
        value: "nfl-preseason",
        sportKey: "americanfootball_nfl_preseason",
        offseason: true,
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
            "Spread": {
                "display": "Spread",
                "value": "spreads"
            },
            "Total Points": {
                "display": "Total Points",
                "value": "totals"
            },
            "Alternate Spreads": {
                "display": "Alternate Spreads",
                "value": "alternate_spreads"
            },
            "Alternate Totals": {
                "display": "Alternate Totals",
                "value": "alternate_totals"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
            "Quarterly Results": {
                "display": "Quarterly Results",
                "value": "h2h_q1,h2h_q2,h2h_q3,h2h_q4,spreads_q1,spreads_q2,spreads_q3,spreads_q4,totals_q1,totals_q2,totals_q3,totals_q4"
            },
            "QB Props": {
                "display": "QB Props",
                "value": "player_pass_tds,player_pass_yds,player_pass_completions,player_pass_attempts,player_pass_interceptions,player_pass_longest_completion"
            },
            "Rushing Props": {
                "display": "Rushing Props",
                "value": "player_rush_yds,player_rush_attempts,player_rush_longest"
            },
            "Receiving Props": {
                "display": "Receiving Props",
                "value": "player_receptions,player_reception_yds,player_reception_longest"
            },
            "TD Scorer Props": {
                "display": "TD Scorer Props",
                "value": "player_anytime_td,player_1st_td"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
            "Defensive Rankings": {
                "display": "Get defensive rankings",
                "prompt": "Use scrape_url to get the NFL defense vs position rankings from https://draftedge.com/nfl/nfl-defense-vs-pos/",
                "premium_only": true
            },
            "Weather Forecast": {
                "display": "Get weather forecast",
                "prompt": "IF the team plays in an outdoor stadium, get the weather forecast by inserting the zip code and scraping this URL: https://forecast.weather.gov/zipcity.php?inputstring={5 digit zip code of home team stadium}",
                "premium_only": true
            },
        }
    },
    {
        display: "🏀 NBA",
        value: "nba",
        sportKey: "basketball_nba",
        offseason: false,
        markets: {
            "Game Spread": {
                strategy: ["Get spreads", "Get stats", "Think step by step about how the matchup will play out based on offense <> opposing defense interaction", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Moneyline": {
                strategy: ["Get h2h odds and stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Over/Under Points": {
                strategy: ["Get totals odds and team stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Player Props": {
                strategy: ["Get NBA totals odds", "Use the event id from totals to get prop odds", "Get stats", "Compare the prop odds to the player's 5 game average and recommend props that are likely to hit"],
                premium_only: false
            },
            "🏆 SageWager Overs Parlay": {
                strategy: ["Get the NBA schedule", "Get the NBA defense per stat rankings from this URLs, filling in the position (G for guard, F for forward, C for center) based on what the user asks for: https://www.fantasysp.com/team-defense-position-categories/basketball/{position letter here}. These are defensive stats so higher = gives up more of that stat", "Identify specific stats to target for OVER props based on what the defense gives up a lot of", "Use the event ids of those team's events to get their actual prop odds", "Get player stats for those with the best matchups and show how many times in the last 5 games the player has gone over their prop line", "Recommend a parlay of over props based on this info",],
                premium_only: true,
                placeholder: "Choose a position (G, F, C) to check for overs"
            },
            "🏆 SageWager Unders Parlay": {
                strategy: ["Get the NBA schedule", "Get the NBA defense per stat rankings from this URLs, filling in the position (G for guard, F for forward, C for center) based on what the user asks for: https://www.fantasysp.com/team-defense-position-categories/basketball/{position letter here}. These are defensive stats so lower = gives up less of that stat", "Identify specific stats to target for UNDER props based on what the defense is strong against", "Use the event ids of those team's events to get their actual prop odds", "Get player stats for those with the best matchups and show how many times in the last 5 games the player has gone UNDER their prop line", "Recommend a parlay of under props based on this info",],
                premium_only: true,
                placeholder: "Choose a position (G, F, C) to check for unders"
            },
            "🏆 SageWager Regression to the Mean Parlay": {
                strategy: ["Get the NBA schedule", "Use the event ids to get prop odds", "Get player stats and identify players that performed significantly above or significantly below their season average on a stat in the last game they played. Bonus points if they have high volatility in their last 5", "Recommend a parlay of props with the prop line based on the theory that they will regress to their mean for that stat in their next game",],
                premium_only: true,
                placeholder: "Pick a team or two to focus on for regression to the mean in their next game"
            },
            "🏆 SageWager Streak Seeker Parlay": {
                strategy: ["Get the NBA schedule", "Use the event ids to get prop odds", "Get player stats and identify players that are on a consistent streak for a particular stat each of their last 5 games", "Recommend a parlay of props with the prop line based on the theory that they will keep their consistent streak going and achieve the same for that stat in their next game",],
                premium_only: true,
                placeholder: "Pick a team or two to check for streaks"
            },
            "🏆 SageWager Follow the Sharps": {
                strategy: ["Get the NBA schedule", "Do research on betting trends for each game", "Share your findings with the user and recommend picks based on following the trends",],
                premium_only: true,
                placeholder: "Pick a game or two to check the sharp picks on"
            },
        },
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
            "Spread": {
                "display": "Spread",
                "value": "spreads"
            },
            "Total Points": {
                "display": "Total Points",
                "value": "totals"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
            "Quarterly Results": {
                "display": "Quarterly Results",
                "value": "h2h_q1,h2h_q2,h2h_q3,h2h_q4,spreads_q1,spreads_q2,spreads_q3,spreads_q4,totals_q1,totals_q2,totals_q3,totals_q4"
            },
            "Player Points": {
                "display": "Player Points",
                "value": "player_points"
            },
            "Alternate Player Points": {
                "display": "Alternate Player Points",
                "value": "player_points_alternate"
            },
            "Player Three Pointers": {
                "display": "Player Three Pointers",
                "value": "player_threes"
            },
            "Player Rebounds": {
                "display": "Player Rebounds",
                "value": "player_rebounds"
            },
            "Player Assists": {
                "display": "Player Assists",
                "value": "player_assists"
            },
            "Player Blocks": {
                "display": "Player Blocks",
                "value": "player_blocks"
            },
            "Player Combos": {
                "display": "Player Stat Combos",
                "value": "player_points_rebounds_assists,player_points_rebounds,player_rebounds_assists"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
            "Defensive Rankings against Position": {
                "display": "Get defensive rankings against position",
                "prompt": "Get the NBA defense against position per stat rankings from these URLs, filling in the position (G for guard, F for forward, C for center) based on what the user asks for: https://www.fantasysp.com/team-defense-position-categories/basketball/{position letter here}. These are defensive stats so higher = gives up more of that stat",
                "premium_only": true
            },
            "Points Formula": {
                "display": "Super Secret Player Points Formula",
                "prompt": "Calculate predicted points using this formula:\n\n1. Base Performance (BP):\n- Calculate weighted PPG over season\n- Weight recent games (last 5-10) more heavily\n- BP = (Σ(PPG_i × w_i))/(Σw_i)\n\n2. Opponent Adjustment (OA):\n- Consider defensive rating vs position\n- Factor in pace of play\n- OA = (League_Avg_DRTG/Opponent_DRTG) × (Opponent_Pace/League_Avg_Pace)\n\n3. Home/Away Adjustment (HAA):\n- HAA = Home_PPG - Away_PPG\n\n4. Pace Adjustment (PA):\n- PA = Team_Pace/League_Avg_Pace\n\n5. Injury/Rest Adjustment (IRA):\n- Consider injuries and rest\n- IRA = Health_Factor × Minutes_Adjustment\n\n6. Recent Form Adjustment (RFA):\n- Last 5 games average × Form_Factor\n\n7. Shot Prediction:\n- SPM = ASG/AMG\n- PSG = SPM × Predicted_Minutes\n\n8. Final Formula:\nPredicted_Points = (BP + OA + HAA + PA + IRA + RFA) × (PSG/ASG) × FG%\n\nAnalyze these factors for 5 players in the matchup to predict their points.",
                "premium_only": true
            }
        }
    },
    {
        display: "⚾️ MLB",
        value: "mlb",
        sportKey: "baseball_mlb",
        offseason: true,
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
            "Runline": {
                "display": "Runline",
                "value": "spreads"
            },
            "Total Runs": {
                "display": "Total Runs",
                "value": "totals"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Inning Total Runs": {
                "display": "1st Inning Total Runs (NRFI / YRFI)",
                "value": "totals_1st_1_innings"
            },
            "1st 5 Innings Total Runs": {
                "display": "1st 5 Innings Total Runs",
                "value": "totals_1st_5_innings"
            },
            "1st 5 Innings Runline": {
                "display": "1st 5 Innings Runline",
                "value": "spreads_1st_5_innings"
            },
            "1st 5 Innings Moneyline": {
                "display": "1st 5 Innings Moneyline",
                "value": "h2h_1st_5_innings"
            },
            "Batter Hits": {
                "display": "Batter Hits",
                "value": "batter_hits"
            },
            "Batter Home Runs": {
                "display": "Batter Home Runs",
                "value": "batter_home_runs"
            },
            "Batter RBIs": {
                "display": "Batter RBIs",
                "value": "batter_rbis"
            },
            "Batter Runs Scored": {
                "display": "Batter Runs Scored",
                "value": "batter_runs_scored"
            },
            "Batter Combo: Hits Runs RBIs": {
                "display": "Batter Combo: Hits Runs RBIs",
                "value": "batter_hits_runs_rbis"
            },
            "Batter Walks": {
                "display": "Batter Walks",
                "value": "batter_walks"
            },
            "Batter Strikeouts": {
                "display": "Batter Strikeouts",
                "value": "batter_strikeouts"
            },
            "Batter Stolen Bases": {
                "display": "Batter Stolen Bases",
                "value": "batter_stolen_bases"
            },
            "Pitcher Strikeouts": {
                "display": "Pitcher Strikeouts",
                "value": "pitcher_strikeouts"
            },
            "Pitcher Earned Runs": {
                "display": "Pitcher Earned Runs",
                "value": "pitcher_earned_runs"
            },
            "Pitcher Hits Allowed": {
                "display": "Pitcher Hits Allowed",
                "value": "pitcher_hits_allowed"
            },
            "Pitcher Walks": {
                "display": "Pitcher Walks",
                "value": "pitcher_walks"
            },
            "Pitcher Outs": {
                "display": "Pitcher Outs",
                "value": "pitcher_outs"
            }
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
            "Advanced Research Sources": {
                "display": "Advanced Research Sources",
                "prompt": "Use these advanced research sources to collect more information: Starting Pitchers: https://www.baseball-reference.com/previews/index.shtml; MLB Stadium Weather: https://swishanalytics.com/mlb/weather; MLB Umpire Factors: https://swishanalytics.com/mlb/mlb-umpire-factors; MLB Field Factors: https://baseballsavant.mlb.com/leaderboard/statcast-park-factors",
                "premium_only": true
            },
            "Starting Pitchers": {
                "display": "Analyze starting pitchers",
                "prompt": "Use https://baseballsavant.mlb.com/probable-pitchers to look up the starting pitchers for today's games and understand the matchup",
                "premium_only": true
            },
            "Series Trends": {
                "display": "Analyze the current series",
                "prompt": "Use search_web to get a summary of the games in the current series so far. Make your picks based on the series matchup only. Remember that sweeping a series is rare in baseball: the home team sweeps a 3 game series about 30% of the time, and the away team sweeps about 22% of the time. For a 4 game series, the home team sweeps about 17% of the time, and the away team sweeps about 11% of the time.",
                "premium_only": true
            },
            "Vibes Only Recent Trend Picks": {
                "display": "Vibes Only (Recent Trend Picks)",
                "prompt": "Identify value plays in baseball by focusing on recent trends and team momentum. Prioritize bets based on teams' performance over the last 10-15 games, recent winning or losing streaks, and key player form. We're looking for bets where the team's current vibe suggests they are likely to continue their positive or negative trend, despite overall season stats. Keep in mind that even the best teams lose about 60 games a season.",
                "premium_only": true
            },
        }
    },
    {
        display: "🏒 NHL",
        value: "nhl",
        sportKey: "icehockey_nhl",
        markets: {
            "Moneyline": {
                strategy: ["Get h2h odds and stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Over/Under Goals": {
                strategy: ["Get totals odds and team stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Player Props": {
                strategy: ["Get NHL totals odds", "Use the event id from the totals odds to get the prop odds", "Get player stats", "Compare the prop odds to the recent player stats and recommend props that are likely to hit"],
                premium_only: false
            },
            "🏆 Totals for Starting Goalies": {
                strategy: ["Get the NHL starting goalies by checking this URL: https://www.capfriendly.com/starting-goalies", "Focus on goalies with the lowest GSA x60 performance (GSA is goals saved above expected, so a negative value means the goalie is allowing more goals than expected based on the shots they have faced)", "Extrapolate their goals against (GA) to calculate the expected score of the game", "Get totals odds and recommend picks based on whether the expectation is over or under the totals line"],
                premium_only: true
            },
            "🏆 SageWager SOG Parlay": {
                strategy: ["Get the NHL defense vs position rankings from https://www.fantasysp.com/team-defense-position-categories/hockey/C (Centers), https://www.fantasysp.com/team-defense-position-categories/hockey/W (Wings), and https://www.fantasysp.com/team-defense-position-categories/hockey/D (Defense). These are defensive stats so higher = worse defense", "Get the NHL schedule", "Identify teams & positions to target for shots on goal props because they are matched up against a bad defense", "Use the event ids of those team's events to get the prop odds", "Recommend a parlay of SOG Over picks based on this info",],
                premium_only: true
            }
        },
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
            "Spreads": {
                "display": "Spreads",
                "value": "spreads"
            },
            "Totals": {
                "display": "Totals",
                "value": "totals"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Period Results": {
                "display": "1st Period Results",
                "value": "h2h_p1,spreads_p1,totals_p1"
            },
            "Player Shots on Goal": {
                "display": "Player Shots on Goal",
                "value": "player_shots_on_goal"
            },
            "Player Points": {
                "display": "Player Points",
                "value": "player_points,player_assists"
            },
            "Goalie Stats": {
                "display": "Goalie Stats",
                "value": "player_total_saves"
            }
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
            "Starting Goalies": {
                "display": "Get starting goalies",
                "prompt": "Use scrape_url to get the NHL starting goalies by checking this URL: https://www.capfriendly.com/starting-goalies ... GSA is goals saved above expected, so a negative value means the goalie is allowing more goals than expected based on the shots they have faced",
                "premium_only": true
            },
            "Defensive Rankings": {
                "display": "Get defensive rankings",
                "prompt": "Get the NHL defense vs position rankings by scraping these URLs https://www.fantasysp.com/team-defense-position-categories/hockey/C (Centers), https://www.fantasysp.com/team-defense-position-categories/hockey/W (Wings), and https://www.fantasysp.com/team-defense-position-categories/hockey/D (Defense). These are defensive stats so higher = worse defense",
                "premium_only": true
            },
        }
    },
    {
        display: "⚾️ NCAA Baseball",
        value: "baseball_ncaa",
        sportKey: "baseball_ncaa",
        offseason: true,
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
            "Runline": {
                "display": "Runline",
                "value": "spreads"
            },
            "Total Runs": {
                "display": "Total Runs",
                "value": "totals"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
            "Starting Pitchers": {
                "display": "Analyze starting pitchers",
                "prompt": "Use search_web to look up the starting pitchers for the matchup",
                "premium_only": true
            },
        }
    },
    {
        display: "🏈 NCAA Football",
        value: "ncaaf",
        sportKey: "americanfootball_ncaaf",
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
            "Spread": {
                "display": "Spread",
                "value": "spreads"
            },
            "Total Points": {
                "display": "Total Points",
                "value": "totals"
            },
            "Alternate Spreads": {
                "display": "Alternate Spreads",
                "value": "alternate_spreads"
            },
            "Alternate Totals": {
                "display": "Alternate Totals",
                "value": "alternate_totals"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
            "Quarterly Results": {
                "display": "Quarterly Results",
                "value": "h2h_q1,h2h_q2,h2h_q3,h2h_q4,spreads_q1,spreads_q2,spreads_q3,spreads_q4,totals_q1,totals_q2,totals_q3,totals_q4"
            },
            "QB Props": {
                "display": "QB Props",
                "value": "player_pass_tds,player_pass_yds,player_pass_completions,player_pass_attempts,player_pass_interceptions,player_pass_longest_completion"
            },
            "Rushing Props": {
                "display": "Rushing Props",
                "value": "player_rush_yds,player_rush_attempts,player_rush_longest"
            },
            "Receiving Props": {
                "display": "Receiving Props",
                "value": "player_receptions,player_reception_yds,player_reception_longest"
            },
            "TD Scorer Props": {
                "display": "TD Scorer Props",
                "value": "player_anytime_td,player_1st_td"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
            "Weather Forecast": {
                "display": "Get weather forecast",
                "prompt": "IF the team plays in an outdoor stadium, get the weather forecast by inserting the zip code and scraping this URL: https://forecast.weather.gov/zipcity.php?inputstring={5 digit zip code of home team stadium}",
                "premium_only": true
            },
        }
    },
    {
        display: "🏀 NCAA Basketball",
        value: "ncaab",
        sportKey: "basketball_ncaab",
        markets: {
            "Game Spread": {
                strategy: ["Get spreads", "Get stats", "Think step by step about how the matchup will play out based on offense <> opposing defense interaction", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Moneyline": {
                strategy: ["Get h2h odds and stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Over/Under Points": {
                strategy: ["Get totals odds and team stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
        },
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
            "Spread": {
                "display": "Spread",
                "value": "spreads"
            },
            "Total Points": {
                "display": "Total Points",
                "value": "totals"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
            "Player Points": {
                "display": "Player Points",
                "value": "player_points"
            },
            "Player Three Pointers": {
                "display": "Player Three Pointers",
                "value": "player_threes"
            },
            "Player Rebounds": {
                "display": "Player Rebounds",
                "value": "player_rebounds"
            },
            "Player Assists": {
                "display": "Player Assists",
                "value": "player_assists"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    },
    {
        display: "🎾 US Open - Men's",
        value: "us-open-men",
        sportKey: "tennis_atp_us_open",
        offseason: true,
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    },
    {
        display: "🎾 US Open - Women's",
        value: "us-open-women",
        sportKey: "tennis_wta_us_open",
        offseason: true,
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    },
    {
        display: "⚽️ Premier League",
        value: "premier-league",
        sportKey: "soccer_epl",
        markets: {
            "Moneyline": {
                strategy: ["Get h2h odds and stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Over/Under Goals": {
                strategy: ["Get totals odds and team stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Game Props": {
                strategy: ["Get totals odds", "Use the event id from the totals odds to get the team prop odds", "Get team stats for the matchup", "Think step by step about how the matchup will play out based on recent trends", "Recommend picks based on the team prop odds"],
                premium_only: false
            },
            "🏆 SageWager xG Trend Over/Under": {
                strategy: ["Get the premier league schedule", "Get the team stats and analyze the 5 game trending expected goals for and against, only for the venue of the upcoming match (home or away)", "Extrapolate those xG trends forward to the matchup and calculate what the expected score is of the match", "Get the totals odds", "Recommend the over or under pick based on the expected goals",],
                premium_only: true
            },
        },
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h,h2h_3_way"
            },
            "Moneyline (Draw no Bet)": {
                "display": "Moneyline (Draw no Bet)",
                "value": "draw_no_bet"
            },
            "Spreads": {
                "display": "Spreads",
                "value": "spreads"
            },
            "Totals": {
                "display": "Total Goals",
                "value": "totals,alternate_totals"
            },
            "Both Teams to Score": {
                "display": "Both Teams to Score",
                "value": "btts"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
            "Expected Goals": {
                "display": "Get expected goals",
                "prompt": "Use search_web to get the recent expected goals trends for and against the teams in the matchup",
                "premium_only": true
            },
        }
        
    },
    {
        display: "⚽️ German Bundesliga",
        value: "bundesliga",
        sportKey: "soccer_germany_bundesliga",
        markets: {
            "Moneyline": {
                strategy: ["Get h2h odds and stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Over/Under Goals": {
                strategy: ["Get totals odds and team stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "🏆 SageWager xG Trend Over/Under": {
                strategy: ["Get the bundesliga schedule", "Get the team stats and analyze the 5 game trending expected goals for and against, only for the venue of the upcoming match (home or away)", "Extrapolate those xG trends forward to the matchup and calculate what the expected score is of the match", "Get the totals odds", "Recommend the over or under pick based on the expected goals",],
                premium_only: true
            },
        },
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h,h2h_3_way"
            },
            "Moneyline (Draw no Bet)": {
                "display": "Moneyline (Draw no Bet)",
                "value": "draw_no_bet"
            },
            "Spreads": {
                "display": "Spreads",
                "value": "spreads"
            },
            "Totals": {
                "display": "Total Goals",
                "value": "totals,alternate_totals"
            },
            "Both Teams to Score": {
                "display": "Both Teams to Score",
                "value": "btts"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    },
    {
        display: "⚽️ Champions League",
        value: "champions-league",
        sportKey: "soccer_uefa_champs_league",
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h,h2h_3_way"
            },
            "Moneyline (Draw no Bet)": {
                "display": "Moneyline (Draw no Bet)",
                "value": "draw_no_bet"
            },
            "Spreads": {
                "display": "Spreads",
                "value": "spreads"
            },
            "Totals": {
                "display": "Total Goals",
                "value": "totals,alternate_totals"
            },
            "Both Teams to Score": {
                "display": "Both Teams to Score",
                "value": "btts"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    },
    {
        display: "⚽️ Europa League",
        value: "europa-league",
        sportKey: "soccer_uefa_europa_league",
        offseason: true,
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h,h2h_3_way"
            },
            "Moneyline (Draw no Bet)": {
                "display": "Moneyline (Draw no Bet)",
                "value": "draw_no_bet"
            },
            "Spreads": {
                "display": "Spreads",
                "value": "spreads"
            },
            "Totals": {
                "display": "Total Goals",
                "value": "totals,alternate_totals"
            },
            "Both Teams to Score": {
                "display": "Both Teams to Score",
                "value": "btts"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    },
    {
        display: "⚽️ MLS",
        value: "mls",
        sportKey: "soccer_usa_mls",
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h,h2h_3_way"
            },
            "Moneyline (Draw no Bet)": {
                "display": "Moneyline (Draw no Bet)",
                "value": "draw_no_bet"
            },
            "Spreads": {
                "display": "Spreads",
                "value": "spreads"
            },
            "Totals": {
                "display": "Total Goals",
                "value": "totals,alternate_totals"
            },
            "Both Teams to Score": {
                "display": "Both Teams to Score",
                "value": "btts"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    },
    {
        display: "⚽️ 2024 UEFA Euro",
        value: "2024-euro",
        sportKey: "soccer_uefa_european_championship",
        offseason: true,
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h,h2h_3_way"
            },
            "Moneyline (Draw no Bet)": {
                "display": "Moneyline (Draw no Bet)",
                "value": "draw_no_bet"
            },
            "Spreads": {
                "display": "Spreads",
                "value": "spreads"
            },
            "Totals": {
                "display": "Total Goals",
                "value": "totals,alternate_totals"
            },
            "Both Teams to Score": {
                "display": "Both Teams to Score",
                "value": "btts"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    },
    {
        display: "⚽️ 2024 Copa America",
        value: "2024-copa-america",
        sportKey: "soccer_conmebol_copa_america",
        offseason: true,
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h,h2h_3_way"
            },
            "Moneyline (Draw no Bet)": {
                "display": "Moneyline (Draw no Bet)",
                "value": "draw_no_bet"
            },
            "Spreads": {
                "display": "Spreads",
                "value": "spreads"
            },
            "Totals": {
                "display": "Total Goals",
                "value": "totals,alternate_totals"
            },
            "Both Teams to Score": {
                "display": "Both Teams to Score",
                "value": "btts"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    },
    {
        display: "🏀 WNBA",
        value: "wnba",
        sportKey: "basketball_wnba",
        offseason: true,
        markets: {
            "Game Spread": {
                strategy: ["Get spreads", "Get stats", "Think step by step about how the matchup will play out based on offense <> opposing defense interaction", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Moneyline": {
                strategy: ["Get h2h odds and stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Over/Under Points": {
                strategy: ["Get totals odds and team stats", "Think step by step about how the matchup will play out based on recent trends", "Give specific reasoning and make a pick"],
                premium_only: false
            },
            "Player Props": {
                strategy: ["Get NBA totals odds", "Use the event id from totals to get prop odds", "Get stats", "Compare the prop odds to the player's 5 game average and recommend props that are likely to hit"],
                premium_only: false
            },
        },
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
            "Spread": {
                "display": "Spread",
                "value": "spreads"
            },
            "Total Points": {
                "display": "Total Points",
                "value": "totals"
            },
            "Team Totals": {
                "display": "Team Totals",
                "value": "team_totals"
            },
            "1st Half Results": {
                "display": "1st Half Results",
                "value": "h2h_h1,spreads_h1,totals_h1"
            },
            "Quarterly Results": {
                "display": "Quarterly Results",
                "value": "h2h_q1,h2h_q2,h2h_q3,h2h_q4,spreads_q1,spreads_q2,spreads_q3,spreads_q4,totals_q1,totals_q2,totals_q3,totals_q4"
            },
            "Player Points": {
                "display": "Player Points",
                "value": "player_points"
            },
            "Alternate Player Points": {
                "display": "Alternate Player Points",
                "value": "player_points_alternate"
            },
            "Player Three Pointers": {
                "display": "Player Three Pointers",
                "value": "player_threes"
            },
            "Player Rebounds": {
                "display": "Player Rebounds",
                "value": "player_rebounds"
            },
            "Player Assists": {
                "display": "Player Assists",
                "value": "player_assists"
            },
            "Player Blocks": {
                "display": "Player Blocks",
                "value": "player_blocks"
            },
            "Player Combos": {
                "display": "Player Stat Combos",
                "value": "player_points_rebounds_assists,player_points_rebounds,player_rebounds_assists"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    },
    {
        display: "🤝 MMA",
        value: "mma_mixed_martial_arts",
        sportKey: "mma_mixed_martial_arts",
        marketKeys: {
            "Moneyline": {
                "display": "Moneyline",
                "value": "h2h"
            },
        },
        researchStrategies: {
            "Betting Trends": {
                "display": "Get real-time betting market trends",
                "prompt": "Use search_web to retrieve real-time betting market info for these bets",
                "premium_only": true
            },
        }
    }
];

export default leagues;
